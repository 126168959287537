.HoursContainer {
    width: '70%';
    text-align: 'center';
    justify-content: 'center';
    align-items: 'center';
    display: 'flex';
    background-color: 'black';
}

.SingleHour {
    margin-right: '7.5px';
    margin-left: '7.5px';
    width: '60px';
    height: '60px';
    border-radius: '8px';
    color: 'white';
    text-align: 'center';
    justify-content: 'center';
    align-items: 'center';
    display: 'flex';
    font-weight: 'bold';
    cursor: 'pointer';
    font-size: '13px';
}